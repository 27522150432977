<template>
  <div>
    <div class="text-right mb-1">
      <b-button
        :to="{name:'business-offers-add'}"
        variant="primary"
      >
        Add Offer
      </b-button>
    </div>
    <b-card header="Search">
      <b-form-row>
        <b-col md="6">
          <search
            placeholder="Keyword"
            :search-query.sync="query.search"
            @refresh="$refs.table.refresh()"
          />
        </b-col>
        <b-col md="6">
          <date-range-filter :date-range.sync="query.range" />
        </b-col>
        <b-col
          md="2"
          class="ml-auto"
        >
          <search-button @refresh="$refs.table.refresh()" />
        </b-col>
      </b-form-row>
    </b-card>
    <b-card>
      <b-table
        ref="table"
        :items="getOffers"
        :fields="fields"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        responsive
        show-empty
      >
        <template #cell(image)="{item}">
          <b-link
            :href="item.image"
            target="_blank"
          >
            View Image
          </b-link>
        </template>
        <template #cell(actions)="{item}">

          <b-dropdown
            variant="link"
            no-caret
          >
            <b-dropdown-item :to="{name:'offer-view',params:{id:item.id} }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{name:'offers-edit',params:{id:item.id} }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="toggleSuggest(item, 'approve')">
              <div>
                <feather-icon icon="CheckIcon" />
                <span
                  class="align-middle ml-50"
                >Approve</span>
              </div>
            </b-dropdown-item>
            <b-dropdown-item @click="toggleSuggest(item, 'decline')">
              <div class="text-danger">
                <feather-icon icon="TrashIcon" />
                <span
                  class="align-middle ml-50"
                >Decline</span>
              </div>
            </b-dropdown-item>
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>
<script>
import Offers from '@/common/compositions/Offers/OffersApi'
import paginationData from '@/common/compositions/common/paginationData'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import Search from '@/common/components/common/Table/Search.vue'
import DateRangeFilter from '@/common/components/common/Table/DateRangeFilter.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'

export default {
  name: 'OffersList',
  components: {
    Pagination,
    Search,
    DateRangeFilter,
    SearchButton,
  },
  data() {
    return {
      query: {
        search: '',
        range: [],
        tagIds: [],
      },
    }
  },
  computed: {
    fields() {
      return [
        { key: 'title', sortable: true },
        { key: 'image' },
        { key: 'description', sortable: true },
        { key: 'start_date', sortable: true },
        { key: 'end_date', sortable: true },
        { key: 'category', formatter: v => v?.name, sortable: true },
        { key: 'type' },
        { key: 'suggestion_status' },
        'actions',
      ]
    },
    formattedQuery() {
      return {
        search: this.query.search,
        startTime: this.query.range[0],
        endTime: this.query.range[1],
        classification: 'business',
        organization: this.$store.getters['mainEntity/getEntityId'],
      }
    },
  },
  setup() {
    const { pagination } = paginationData()
    const { listSuggestedOffersRequest, toggleSuggestedOffersRequest } = Offers()
    return {
      pagination, listSuggestedOffersRequest, toggleSuggestedOffersRequest,
    }
  },
  methods: {
    getOffers(ctx) {
      return this.listSuggestedOffersRequest({
        ...this.formattedQuery,
        page: this.pagination.currentPage,
        orderParameter: ctx.sortBy,
        orderDir: ctx.sortDesc ? 'DESC' : 'ASC',
      }).then(res => {
        this.pagination.totalRows = res.data.pagination.total
        return res.data.data || []
      })
    },
    toggleSuggest(item, newStatus) {
      this.toggleSuggestedOffersRequest(item.id, newStatus)
      this.$refs.table.refresh()
    },
  },
}
</script>
<style lang="">

</style>
